import httpUtil from "@/utils/httpUtil";

/**授权接口 */
export const componentloginpage =  params => httpUtil.post("/component/componentloginpage", params);


/**授权信息 */
export const getCompanyAccountList = async params => httpUtil.post("/companyAccount/getCompanyAccountList", params);


/**移交企业 */
export const companyTransfer = async params => httpUtil.post("/companyGoods/companyTransfer", params);


