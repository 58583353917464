<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户端" name="first"> <userTerminal :accountMap="accountMap"  @getAppListInfo="getAppListInfo" v-if="activeName == 'first'" /> </el-tab-pane>
      <el-tab-pane label="crm端" name="second"> <crm :accountMap="accountMap" @getAppListInfo="getAppListInfo" v-if="activeName == 'second'" /> </el-tab-pane>
      <el-tab-pane label="用户端支付" name="third"><userpay :accountMap="accountMap" v-if="activeName == 'third'" /> </el-tab-pane>
      <el-tab-pane label="crm支付" name="fourthly"><crmpay :accountMap="accountMap" v-if="activeName == 'fourthly'" /> </el-tab-pane>
      <el-tab-pane label="登录设置" name="fifth" v-if="userInfo.main"><authorizationSwitch :accountMap="accountMap" v-if="activeName == 'fifth'" /> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import userTerminal from './components/userTerminal';
import crm from './components/crm';
import userpay from './components/userpay';
import crmpay from './components/crmpay';
import authorizationSwitch from './components/authorizationSwitch';
import { getCompanyAccountList } from "@/api/authorization"
export default {
  data() {
    return {
      activeName: "first",
      accountMap:'',
      userInfo:'',
    };
  },
  components:{
    userTerminal,
    crm,
    userpay,
    crmpay,
    authorizationSwitch
  },
  created(){
    this.userInfo = JSON.parse(localStorage.getItem('info'))
    this.getAppListInfo()
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
    async getAppListInfo(){
      const result =  await getCompanyAccountList({appid:true})
      console.log(result)
      if(result.data){
        let tmp = {};
        result && result.data.map(val => {
            tmp[val.accountType] = val;
        });
        this.accountMap = tmp;
        console.log(this.accountMap) 
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>